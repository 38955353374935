<template>
  <div class="row">
    <div class="col-md-12"  v-if="Object.keys(user).length !== 0">
      <div class="card">
        <div class="card-body">
          <p>Total Accessories: {{ user_accessories.length }}</p>
          <h5 v-for="(user_accessory, index) in user_accessories" :key="index">
            {{ index + 1 }}: {{ user_accessory.name }}
          </h5>
          <a-skeleton active :loading="loading"></a-skeleton>
        </div>
      </div>
      <a-button type="danger" class="text-center mr-2" @click="$router.go(-1)">Back</a-button>
    </div>
    <div class="col-md-12" v-else>
      <div class="card">
        <div class="card-body">
          <h5 class="text-center text-secondary">{{ message }}</h5>
        </div>
      </div>
      <a-button type="danger" class="text-center mr-2" @click="$router.go(-1)">Back</a-button>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'

export default {
  name: 'user_accessory_view',
  data() {
    return {
      user: {},
      user_accessories: [],
      loading: false,
      show: false,
      spinning: false,
      message: null,
    }
  },
  mounted() {
    this.getAdminHubs()
  },
  methods: {
    getAdminHubs() {
      const userId = this.$route.params.user_id
      apiClient.get('api/admin-scenario/accessory-users/' + userId)
        .then((response) => {
          if (response.data.error === true) {
            this.message = response.data.message
          } else {
            this.user = response.data.user
            this.user_accessories = response.data.user.user_accessories
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>
table {
  position: relative;
}

th {
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 1;
}
</style>
